import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import PlaidLink from 'react-plaid-link'

import {
	Flex,
	Heading,
	Block,
	Paragraph,
} from 'reakit'

import { Container, PhoneBody, Modal } from '../../../../components/layouts'


import {
	Card,
	Button,
	Image,
	StripeCheckout,
	Loader
} from '../../../../components/ui'

import {
	linkBank, resetBankState
} from '../../../../factory'
import {PLAID_ENV} from '../../../../factory/types'

import Layout from '../../../../components/layout'

import theme from '../../../../theme/content'
import {getUrlVars} from '../../../../util'
import { navigate } from '@reach/router'


class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			has_chargable_accounts: false,
			showError: false
		}

		this.setChargeable = this.setChargeable.bind(this)
		this.renderLinkAccount = this.renderLinkAccount.bind(this)
		this.handleOnSuccess = this.handleOnSuccess.bind(this)
	}

	componentDidMount() {
		this.props.resetBankState()
		this.setChargeable(getUrlVars().chargeable === 'true')
	}

	setChargeable(has_chargable_accounts) {
		this.setState({has_chargable_accounts})
	}

	handleOnSuccess(plaid_token, _) {
		this.props.linkBank(plaid_token, this.props.token, ()=>navigate('/admin/profile/'), ()=> this.setState({showError: true}))
	}


	handleOnExit() {
		// handle the case when your user exits Link
	}

	renderLinkAccount() {
		const {has_chargable_accounts} = this.state

		if (has_chargable_accounts) {
			return (<Flex column height={'100%'} justifyContent={"center"} alignItems={"center"} marginTop={25}>
				<Card style={{backgroundColor: theme.colors.sunglow, width: '80%', borderRadius: 25, textAlign: 'center'}}>
					<Heading as="h1" fontSize={"24px"} textAlign={'center'}>Link an account for Round Ups</Heading>
					<Paragraph>Link as many cards as you’d like. The more accounts the merrier! We will use these accounts to track your round-ups.</Paragraph>
					<PlaidLink
						clientName="Flourish"
						env={PLAID_ENV}
						product={"transactions"}
						publicKey="d0d722e2447e775a33bc590c6687ac"
						onExit={this.handleOnExit}
						style={{borderRadius: '50px', width: 200, height: '2.5em', fontFamily: 'work-sans'}}
						onSuccess={this.handleOnSuccess}>
						<Paragraph>Search Banks</Paragraph>
					</PlaidLink>
				</Card>
				<div style={{height: 2, width: '25%', minWidth: 250, background: theme.colors.mineShaft, marginTop: 25, marginBottom: 10}}/>
				<Paragraph>Looking to link another funding source?</Paragraph>
				<Modal
					link={{text: "Link a Credit Card"}}
				>
					<div style={{width: '80%'}}>
						<Heading as={"h1"} fontSize={'24px'}>Please enter your credit card:</Heading>
						<StripeCheckout/>
					</div>
				</Modal>
			</Flex>)
		}
		return (
			<Flex column style={{alignItems: 'center'}}>
				<Image
					src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding2.png"}
					width={'125px'}
					marginTop={'25px'}
					height={'auto'}
					marginBottom={"0px"}
				/>
				<Heading as="h1" fontSize={'22px'}>Link a Funding Source</Heading>
				<Flex row alignItems={"center"} width={'80%'} margin={'auto'} marginBottom={15}>
					<Flex column alignItems={'center'} textAlign={'center'} marginRight={'10px'}>
						<Paragraph style={{marginBottom: 0, fontWeight: 700, width: 75}}>Option</Paragraph>
						<div style={{width: 50, height: 50, background: theme.colors.sunglow, borderRadius: 25, marginTop: 0 }}>
							<Heading color={'white'} height={"50px"} width={'50px'}textAlign={'center'} marginTop={'10px'}>1</Heading>
						</div>
					</Flex>
					<Paragraph style={{height: '100%', }}>
                        Link a <strong>checking account.</strong> It allows us to send the most money to your nonprofit.
					</Paragraph>
				</Flex>
				<PlaidLink
					clientName="Flourish"
					env={PLAID_ENV}
					product={"transactions"}
					publicKey="d0d722e2447e775a33bc590c6687ac"
					onExit={this.handleOnExit}
					style={{borderRadius: '50px', width: 250, height: '2.5em', fontFamily: 'work-sans', background: theme.colors.sunglow, fontWeight: 900}}
					onSuccess={this.handleOnSuccess}>
					<Paragraph>Link A Checking Account</Paragraph>
				</PlaidLink>
				<Flex row alignItems={"center"} justifyContent={'center'} width={'80%'} margin={'5px auto'}>
					<div style={{height: 2, width: '25%', background: theme.colors.mineShaft}}/>
					<Heading as="h4" margin={'25px 15px'} fontSize={'25px'} textAlign={'center'}>OR</Heading>
					<div style={{height: 2, width: '25%', background: theme.colors.mineShaft}}/>
				</Flex>
				<Flex row alignItems={"center"} width={'80%'} margin={'auto'} marginBottom={15}>
					<Flex column alignItems={'center'} textAlign={'center'} marginRight={'10px'}>
						<Paragraph style={{marginBottom: 0, fontWeight: 700, width: 75}}>Option</Paragraph>
						<div style={{width: 50, height: 50, background: theme.colors.sunglow, borderRadius: 25, marginTop: 0 }}>
							<Heading color={'white'}  height={"50px"} width={'50px'}textAlign={'center'} marginTop={'10px'}>2</Heading>
						</div>
					</Flex>
					<Paragraph style={{height: '100%', }}>
                        Link a <strong>credit card</strong>, but it can be more expensive for your cause.
					</Paragraph>
				</Flex>
				<Modal
					button={{text: "Link a Credit Card", style: {width: "250px", maxWidth: '250px', background: 'white', border: `2px solid ${theme.colors.sunglow}`}}}
				>
					<div style={{width: '80%'}}>
						<Heading as={"h1"} fontSize={'24px'}>Please enter your credit card:</Heading>
						<StripeCheckout/>
					</div>
				</Modal>
			</Flex>
		)
	}

	render() {
		const {bank_pending, error} = this.props
		const {has_chargable_accounts, showError} = this.state
		return (
			<Layout>
				<Container>
					<PhoneBody title={has_chargable_accounts ?  'Add Account' : "Add Funding Source"}>
						{this.renderLinkAccount()}
						{bank_pending && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Loader/>
									<Heading as="h1" fontSize={24}>Linking Account...</Heading>
								</div>
							</div>
						</div>
						}
						{showError && <div style={{position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, background: "rgba(10, 10, 10, .4)"}}>
							<div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', width: '100%', height: '100%'}}>
								<div style={{background: 'white', flexDirection: 'column', width: '55%', maxWidth: 250, margin: 'auto', alignItems: 'center', textAlign: 'center', padding: 10}}>
									<Heading as="h1" fontSize={24}>Uh oh!</Heading>
									<Paragraph>{error}</Paragraph>
									<Button onClick={()=>this.setState({showError: false})}>Close</Button>
								</div>
							</div>
						</div>
						}
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	user: PropTypes.object,
	token: PropTypes.string,
	bank_pending: PropTypes.bool,
	error: PropTypes.string,
	resetBankState: PropTypes.string,
	linkBank: PropTypes.string
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		token: state.authState.accessToken,
		bank_pending: state.bankState.bank_pending,
		error: state.bankState.error
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		linkBank: (plaid_token, token, onSuccess, onErrorCallback) => dispatch(linkBank(plaid_token, token, onSuccess, onErrorCallback)),
		resetBankState: () => dispatch(resetBankState())
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
